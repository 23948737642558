import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import FullCalendar from "@fullcalendar/react";
// FullCalendar plugin import order does matter here
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Calendar as PRCalendar } from "primereact/calendar";
import EventService from "../service/EventsService";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { ET_OFFSET } from "../utilities/constants";
import { getTimeInEtTimezone } from '../utilities/functions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const CalendarPage = (props) => {
  const [eventDialog, setEventDialog] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  // const [startDate, setStartDate] = useState(new Date(2022, 11, 15));
  const [clickedEvent] = useState(null);
  const [changedEvent, setChangedEvent] = useState({
    title: "",
    start: null,
    end: null,
    allDay: null
  });
  const [events, setEvents] = useState(null);

  const tooltipRef = useRef();
  const toast = useRef(null);
  const calendarRef = React.createRef()
  const history = useHistory();

  useEffect(() => {
    document.title = "Hub | Home";
    setBreadcrumb();
    const eventService = new EventService();
    eventService.getCalendarEventsV2(props.user).then((data) => {

      const hour = 60 * 60 * 1000;
      const convertedEvents = data.map((event) => {
        const d = new Date(event.et_time);
        const etDateTime = d.getTime() + ET_OFFSET * hour;
        return {
          ...event,
          title: event.name,
          start: etDateTime, 
          end: etDateTime 
        };
      });
      setEvents(convertedEvents);
    });
  }, []);

  const setBreadcrumb = () => {
    const breadcrumbDiv = document
      .getElementsByClassName("layout-breadcrumb viewname")[0];
    const breadcrumbText = `Game Schedule`;
    breadcrumbDiv.innerHTML = breadcrumbText;
  };
  const reset = () => {
    setEventDialog(false);
  };

  const footer = (
    <>
      <Button
        type="button"
        label="Cancel"
        icon="pi pi-refresh"
        className="p-button-text"
        onClick={reset}
      />
    </>
  );

  const closeTooltip = () => {
    tooltipRef.current.hideTooltip();
    document.getElementById("eventTooltipId").style.display = "none";
  };
  const openEventPage = (eventInfo) => {
    const id = eventInfo.id;
    if (props.user.club === 1)
      id && history.push({ pathname: `/event/${id}`, state: { metaInfo: eventInfo } });
  };
  const openPlaylistPage = (id) => {
    id && history.push({ pathname: `/playlist/${id}`, state: {} });
  };
  // function getTimezoneOffset(timeZone) {
  //   const now = new Date();
  //   const tzString = now.toLocaleString("en-US", { timeZone });
  //   const localString = now.toLocaleString("en-US");
  //   const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000;
  //   const offset = diff + now.getTimezoneOffset() / 60;

  //   return -offset;
  // }
  const EventTooltipComponent = (props) => {
    const id = props.id;
    const event = events.find((ev) => ev.id === parseInt(id));

    if (!event) return null;

    return (
      <>
        <div className="calendarEventTooltipHeader">
          <span onClick={() => openEventPage(event)}>{event.title}</span>
          <i 
            onClick={closeTooltip} 
            style={{ position: "absolute", right: 2, top: 2 }} 
            className="pi pi-times"></i>
        </div>
        <div className="calendarEventTooltipBody">
          <div className="tooltipTableRow">
            <div className="tooltipTableCellLeft">
              Competition
            </div>
            <div className="tooltipTableCellRight">
              {event.competition}
            </div>
          </div>
          <div className="tooltipTableRow">
            <div className="tooltipTableCellLeft">
              <span>
                <img 
                  src={
                    event.venue.country &&
                    event.venue.country.code && 
                    event.venue.country.code.toLowerCase() === "us" 
                    ? "assets/demo/images/flags/us-flag.png" 
                    : "assets/demo/images/flags/flag_placeholder.png"} 
                    alt={event.venue.region} 
                    className={event.venue.country ? 
                      `flag flag-${event.venue.country.code.toLowerCase()}` : 
                      'flag flag-ca'} 
                    width={30} 
                    height={20} 
                />
                <span 
                  style={{ marginLeft: ".5em", verticalAlign: "middle", fontWeight: "400" }} 
                  className="image-text">
                  {event.venue.city}
                </span>
              </span>
            </div>
            <div className="tooltipTableCellRight">
              {/* TODO: change times vice-versa, local should be default */}
              {getTimeInEtTimezone(event.et_time, false)}
            </div>
          </div>

          {event.outputs.length > 0 && (
            <>
              <Divider align="center">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-list mr-2"></i>&nbsp;
                  <b>Feeds</b>
                </div>
              </Divider>

              {event.outputs.map((output, i) => (
                <div
                  onClick={() => openPlaylistPage(output.id)}
                  className={
                    i % 2 === 0
                      ? "cursorPointer tooltipTableRow greyTableRow"
                      : "cursorPointer tooltipTableRow"
                  }
                  style={{ paddingLeft: 10, paddingRight: 30, color: "#1976D2" }}
                  key={i}>
                  <div className="tooltipTableCellLeft">{output.name}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    );
  };
  function timeFormat(info) {
    let hours = info.date.hour;
    let minutes = info.date.minute;
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + ampm + " ET";

    return <span style={{ fontSize: "0.9rem", fontWeight: 600 }}>{strTime}</span>;
  }
  const CalendarEventTitle = (props) => {
    const isListView = props.eventInfo.view.type === "listMonth";
    const titleParts = props.eventInfo.event.title
      .replace(/\s/g, "").split("@");

    let standardName = false; // XXX @ YYY || XXX@YYY

    if (titleParts.length === 2) {
      if (titleParts[0].length === 3 && titleParts[1].length === 3) standardName = true;
    }
    return (
      <span className="calendarEventTitle">
        {standardName ? (
          <span>
            {isListView ? (
              <span>
                <span className="calendarTeamName">{titleParts[0]}</span>
                <span>&nbsp;&nbsp;@&nbsp;&nbsp;</span>
                <span className="calendarTeamName" style={{ width: 36 }}>
                  {titleParts[1]}&nbsp;&nbsp;
                </span>
              </span>
            ) : (
              <span>
                <span className="calendarTeamName">{titleParts[0]}</span>
                <span>&nbsp;@&nbsp;</span>
                <span className="calendarTeamName">{titleParts[1]}</span>
              </span>
            )}
          </span>
        ) : (
          props.eventInfo.event.title
        )}
        <span className="calendarEventLocation"> {getTimeInEtTimezone(props.eventInfo.event._def.extendedProps.et_time)}</span>
      </span>
    );
  };
  const renderEventContent = (eventInfo) => {
    const numOfOutputs = eventInfo.event._def.extendedProps.outputs.length;

    if (!eventInfo) return null;
    return (
      <div>
        <span
          onClick={() => {
            if (eventInfo.event._def.extendedProps.outputs.length > 0) 
              openPlaylistPage(eventInfo.event._def.extendedProps.outputs[0].id);
            else 
              toast.current.show({
                severity: "warn",
                summary: "No playlists for this event so far",
                detail: "No playlists",
                life: 4000
              });      
          }}
          className="calendarEventLink"
        >
          <CalendarEventTitle eventInfo={eventInfo} />
        </span>
        &nbsp;
        <span
          data-event="click focus"
          data-tip={eventInfo.event.id}
          data-for="eventTooltipId"
          id={"id" + eventInfo.event.id}
        >
          {numOfOutputs > 0 ? (
            <i className="pi pi-external-link calendarEventTooltipIconBlue"></i>
          ) : (
            <i className="pi pi-external-link calendarEventTooltipIconGrey"></i>
          )}
        </span>
        <ReactTooltip
          ref={tooltipRef}
          id="eventTooltipId"
          globalEventOff="click"
          className="eventTooltip card"
          getContent={(eventId) => <EventTooltipComponent id={eventId} />}
          effect="solid"
          delayHide={10}
          delayUpdate={10}
          place={"bottom"}
          type={"light"}
        />
      </div>
    );
  }

  /*
  ******************  MAIN  **********************
  */

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-container">
          {showDatePicker && (
            <DatePicker
              fixedHeight
              dateFormat="MM/yyyy"
              inline
              showMonthYearPicker
              // selected={startDate}
              onChange={(date) => {
                // setStartDate(date);
                calendarRef.current.getApi().gotoDate(new Date(date.getTime() + 5*24*60*60*1000));
                setShowDatePicker(false);
              }}
            />
          )}

          <Toast ref={toast} />
          {events ? (
            <FullCalendar
              ref={calendarRef}
              height={"auto"}
              timeZone="America/New York"
              // initialDate={new Date('2022-10-10')}
              events={events}
              plugins={[dayGridPlugin, listPlugin]}
              // hide weekday in list view
              listDaySideFormat={false}
              themeSystem={'bootstrap'}

              eventTimeFormat={timeFormat}
              headerToolbar={{
                left: "twoMonthBack,prev,gotoButton,next,twoMonthFwd",
                center: "title",
                initialView: "dayGridMonth",
                right: "dayGridMonth,listMonth"
              }}
              customButtons={{
                gotoButton: {
                  text: <i 
                    title="Jump to month" 
                    className="layout-menuitem-icon pi pi-fw pi-calendar" 
                  />,
                  click: () => {
                    setShowDatePicker(prevShowDatePicker => !prevShowDatePicker);
                  }
                },
                twoMonthBack: {
                  icon: 'chevrons-left',
                  text: 'Two months back',
                  click: () => {
                    const api = calendarRef.current.getApi();
                    api.incrementDate({ months: -2 });
                  }
                },
                twoMonthFwd: {
                  icon: 'chevrons-right',
                  text: 'Two months forward',
                  click: () => {
                    const api = calendarRef.current.getApi();
                    api.incrementDate({ months: 2 });
                  }
                }
              }}
              buttonText={{ month: "Calendar", list: "List" }}
              defaultTimedEventDuration={0}
              firstDay={1}
              // editable
              selectable
              fixedWeekCount={false}
              eventContent={renderEventContent}
            />
          ) : (
            <>
              <div style={{ textAlign: "center", marginTop: "25vh" }}>
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </>
          )}
          <Dialog
            visible={eventDialog && !!clickedEvent}
            style={{ width: "450px" }}
            header="Event Details"
            footer={footer}
            modal
            closable
            onHide={() => setEventDialog(false)}>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="title">Title</label>
                <InputText
                  id="title"
                  value={changedEvent.title}
                  onChange={(e) =>
                    setChangedEvent({ ...changedEvent, ...{ title: e.target.value } })
                  }
                  required
                  autoFocus
                />
              </div>
              <div className="p-field">
                <label htmlFor="start">Time</label>
                <PRCalendar
                  id="start"
                  value={changedEvent.start}
                  onChange={(e) => setChangedEvent({ ...changedEvent, ...{ start: e.value } })}
                  showTime
                  appendTo={document.body}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};